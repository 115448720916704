.Container {
  max-width: 600px;
  margin: 2rem auto;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.Title {
  font-size: 1.75rem;
  font-weight: bold;
}

.Subtitle {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.Card {
  background-color: #fff;
  border-radius: 0.75rem;
  box-shadow: 0 2px 8px rgb(0 0 0 / 5%);
  padding: 1rem;
}

.CardContent {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.CheckboxRow {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.InputRow {
  display: flex;
  gap: 0.5rem;
}

.Input {
  flex: 1;
  padding: 0.5rem 0.75rem;
  border: 1px solid #ccc;
  border-radius: 0.5rem;
}

.List {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.ListItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f9fafb;
  border-radius: 0.5rem;
  padding: 0.5rem 0.75rem;
}

.Actions {
  display: flex;
  gap: 0.5rem;
}

.IconButton {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.25rem;
}

.Link {
  font-size: 0.875rem;
  color: #2563eb;
  text-decoration: underline;
}

.StickyInputRow {
  position: sticky;
  top: 0;
  z-index: 20;
  background: #fff;
  box-shadow: 0 2px 4px rgb(0 0 0 / 4%);
  border-radius: 12px;
}
